<template>
  <v-container>
    <h1 class="mb-3">{{ $metaInfo.title }}</h1>
    <div class="mb-4">
      <v-btn color="primary" @click="adminDialog = !adminDialog"
        >Добавить пользователя</v-btn
      >
    </div>
    <div style="min-height: 4px">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="pending"
      ></v-progress-linear>
    </div>
    <v-sheet>
      <v-simple-table :key="viewKey" class="mb-3">
        <template #default>
          <thead>
            <tr>
              <th class="text-left" width="40px">ID</th>
              <th class="text-left">ФИО</th>
              <th class="text-left">email</th>
              <th class="text-left">Суперпользователь</th>
              <th style="width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="person in users" :key="person.talent_id">
              <td>
                {{ person.talent_id }}
              </td>
              <td>
                <router-link
                  title="Перейти к карточке контакта"
                  target="_blank"
                  :to="{ name: 'contact', params: { id: person.talent_id } }"
                >
                  {{ person.talent_user.first_name }}
                  {{ person.talent_user.last_name }}
                </router-link>
              </td>
              <td>
                <div>{{ person.talent_user.email }}</div>
              </td>
              <td>
                {{ person.talent_user.is_superuser ? "Да" : "Нет" }}
              </td>
              <td class="text-right">
                <v-hover
                  v-if="!person.talent_user.is_superuser"
                  v-slot="{ hover }"
                >
                  <v-btn
                    :color="hover ? 'error' : 'grey lighten-1'"
                    title="Удалить пользователя"
                    icon
                    @click.stop="handleDeleteUser(person)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-hover>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
    <v-pagination
      v-if="pagesCount > 1"
      v-model="page"
      :total-visible="7"
      :length="pagesCount"
    ></v-pagination>

    <add-admin-dialog v-model="adminDialog" @addUser="getList(page)" />
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
import AddAdminDialog from "@/components/dialogs/AddAdminDialog";
export default {
  name: "Users",
  components: {
    AddAdminDialog,
  },
  data() {
    return {
      size: 20,
      total: 0,
      page: 1,
      users: [],
      pending: false,
      viewKey: 1,
      adminDialog: false,
    };
  },
  metaInfo() {
    return {
      title: "Список пользователей CRM",
    };
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.total / this.size);
    },
  },
  watch: {
    page: {
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async handleDeleteUser(user) {
      const confirm = await this.$root.$confirm(
        "Удалить пользователя",
        `Вы уверены, что хотите удалить этого пользователя из списка пользователей CRM? `,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (confirm) {
        try {
          await apiClient({
            method: "DELETE",
            url: `/users/${user.talent_id}`,
          });
          this.getList();
        } catch (error) {
          this._showError(`Не удалось удалить пользователя. ${error.message}`);
        }
      }
    },
    async getList() {
      const { size, page } = this;
      if (this.pending) return;
      this.pending = true;
      const params = {
        limit: size,
        offset: size * (page - 1),
      };
      const { data } = await apiClient({
        method: "GET",
        url: "/users",
        params,
      });
      this.pending = false;
      this.users = data.results;
      this.total = data.count;
    },
  },
};
</script>

<style></style>
