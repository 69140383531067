<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5 rm-dialog-title"
        >Добавить пользователя CRM</v-card-title
      >
      <v-card-text class="black--text">
        <v-alert class="mb-4 mt-4" dense border="left" type="error">
          Будьте внимательны при добавлении пользователя, так как пользователи
          CRM имеют доступ к ПД всех пользователей платформы Талант.
        </v-alert>
        <div class="d-flex">
          <v-text-field
            v-model.trim="searchId"
            label="ID пользователя"
            class="mr-3"
            clearable
            :error-messages="searchError && [searchError]"
          ></v-text-field>
          <v-btn class="mt-3" @click="handleSearch">Найти</v-btn>
        </div>
        <div v-if="searchUser && searchUser.id === Number(searchId)">
          <div class="text-subtitle-1">
            <strong
              >{{ searchUser.first_name }} {{ searchUser.last_name }}</strong
            >
          </div>
          <div class="text-caption">{{ searchUser.email }}</div>
          <div v-if="error" class="mb-2 error--text">
            Не удалось добавить пользователя. {{ error }}
          </div>
          <v-btn
            class="mt-2"
            small
            color="primary"
            :loading="pending"
            @click="handleSubmit"
            >Добавить пользователя</v-btn
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiClient, talentClient } from "@/api";
const initialState = () => {
  return {
    searchId: "",
    searchError: "",
    searchUser: null,
    searchPending: false,
    pending: false,
  };
};
export default {
  name: "AddAdminDialog",
  props: {
    value: Boolean,
  },
  data() {
    return {
      ...initialState(),
    };
  },
  watch: {
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    handleClose() {
      this.$emit("input", false);
    },
    async handleSearch() {
      if (this.searchPending) return;
      this.searchPending = true;
      this.searchError = "";
      this.error = "";
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${this.searchId}/`,
        });
        if (!data?.id) {
          this.searchError = "Пользователь не найден";
          return;
        } else {
          this.searchUser = data;
        }
      } catch (error) {
        this.searchError = error.message;
      } finally {
        this.searchPending = false;
      }
    },
    async handleSubmit() {
      const { searchUser } = this;
      if (!searchUser) return;
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "POST",
          url: "/users",
          data: {
            talent_id: searchUser?.id,
          },
        });
        this.$emit("addUser", data);
        this.reset();
      } catch (error) {
        if (
          Array.isArray(error.originalData) &&
          error.originalData[0].validation_error === "unique"
        ) {
          this.error = "Этот пользователь уже добавлен в CRM";
        } else {
          this.error = error.message;
        }
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
